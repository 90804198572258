<template>
  <div>
    <PageMenu :breadcrumbs="breadcrumbs" :page="page">
      <template v-slot:toolbar>
        <b-button v-if="user.hasPermission('users:user.create')" variant="primary" size="sm" v-b-modal.modal-new-user>
          <BIconPersonPlus/> New User
        </b-button>
        <b-modal v-if="user.hasPermission('users:user.create')" id="modal-new-user" title="Create new user" hide-footer>
            <UserCreate @user-created="goToUser"/>
        </b-modal>
        <b-dropdown v-if="user.hasPermission(['users:role.search','users:permission.search'])" id="dropdown-settings" variant="secondary" text="Settings" class="ml-3" size="sm" right no-caret>
          <template #button-content>
            <BIconGear scale="0.9"/>
            <span class="sr-only">Settings</span>
          </template>
          <b-dropdown-item v-if="user.hasPermission('users:role.search')" :to='{name: "users.roles"}'>Roles</b-dropdown-item>
          <b-dropdown-item v-if="user.hasPermission('users:permission.search')" :to='{name: "users.permissions"}'>Permissions</b-dropdown-item>
        </b-dropdown>
      </template>
    </PageMenu>

    <div class="page-with-menu">
      <b-card>
        <b-card-text>
          <UserFilters @on-search="applyFilters" showParentsFilters="yes"/>
        </b-card-text>
      </b-card>
      <UserList v-if="user.hasPermission('users:user.search')" class="mt-3" :table="table" :paginationRouter="paginationRouter"/>
    </div>
  </div>
</template>

<script>
import {UserList, UserCreate,UserFilters} from './../../components/users';
import moment from 'moment';
import PageMenu from '@/common/components/PageMenu';
import {BIconPersonPlus,BIconGear} from 'bootstrap-vue';
export default {
  props: ['user'],
    components: {
      BIconPersonPlus,BIconGear,
      PageMenu,UserList,UserCreate,UserFilters
    },
    data(){
      return {
        page: {
            title: "Users",
        },
        breadcrumbs: [
            {
                title: "Dashboard",
                url: this.$router.resolve({name: "app.dashboard"}).href
            },
            {
                title: "Browse Users",
            }
        ],
        table: {
          isLoading: false,
          records: [],
          pagination: {
            currentPageNo: 1
          },
          fields: [
            {
              key: 'createdAt',
              label: "Joined",
              formatter: (value) => {
                return moment(value).format('DD/MM/YYYY');
              }
            },
            {
              key: 'name',
              label: "Name"
            },
            {
              key: 'email',
              label: "E-Mail"
            },
            {
              key: 'roles',
              label: "Role",
            },
            {
              key: 'status',
              label: "Status"
            },
            {
              key: 'locale',
              label: "Language"
            },
            {
              key: 'totalChildren',
              label: "Subaccounts",
              formatter: (value) => {
                return value ? value : 0;
              }
            },
            {
              key: 'options',
              label: ""
            },
          ]
        }
      }
    },
    methods: {
      goToUser(data){
        this.$router.push({name: "users.user.details", params: {userId: data.id}});
      },
      paginationRouter(pageNum){
        let query =  {};
        if(pageNum > 1){
            query.page = pageNum;
        }

        return this.$router.resolve({name: 'users',query: query}).href;
      },

      getResults({pageNo}, filters){
        this.table.isLoading = true;
        this.$api.get('users',{
          params: Object.assign({
            perPage: 20,
            page: pageNo,
            orderBy: "datetime",
            arrange: "desc",
            parentsOnly: "y"
          },filters || {})
        }).then(({data}) => {
          this.table.isLoading = false;
          this.table.records = data.records;
          this.table.pagination = data.pagination;
          this.page.title = `Users (${data.total})`;
        });
      },

      applyFilters(filters){
        this.getResults({pageNo: 1}, filters);
      }
    },
    mounted(){
      this.getResults({pageNo: 1});
      this.$emit('set-sidebar',{active: "users"});
    },

    watch: {
      "$route.query.page": function(pageNo){
        this.getResults({pageNo: pageNo});
      }
    },
};
</script>
